<template>
  <div class="pt-4 md:pt-0">
    <SeoMeta :title="$t('seo.title')" :description="$t('seo.description')" />
    <ClearCacheMeta
      v-if="
        featuredProductsError || featuredProductCategoriesError || bannerError
      "
    />
    <div
      v-if="mobileStartpageNavButtonsEnabledLocales.includes(locale)"
      class="mx-auto flex max-w-6xl justify-between space-x-2 overflow-scroll px-4 sm:justify-center md:hidden"
    >
      <Button
        v-for="button in mobileNavButtons.sv"
        :key="button.title"
        color="secondary"
        :link="button.path"
        size="sm"
        text-size="sm"
        @click="trackMobileNavButtonClick(button.title)"
      >
        {{ button.title }}
        <DynamicPictogram name="chevron" size="md" class="rotate-180" />
      </Button>
    </div>
    <Hero
      v-if="blackWeekEnabledLocales.includes(locale)"
      :heading="$t('hero.heading')"
      :paragraph="$t('hero.paragraph')"
      :link="localePath({ name: 'campaigns-black-week' })"
      :link-text="$t('hero.link_text')"
      bg-class-names="bg-black"
      shape-class-names="text-black"
      text-class-names="text-white"
      :image-src-desktop="HeroImgDesktopBlackWeek"
      :image-src-mobile="HeroImgMobileBlackWeek"
    />
    <div class="mx-auto mt-4 max-w-6xl px-4 md:mt-6 md:px-6">
      <BannerList
        :banners="banners"
        :show-secondary="false"
        :is-startpage="true"
        :loading="bannersLoading"
        :loading-count-primary="4"
        class="mb-12"
      />

      <div class="mb-12 grid grid-cols-2 gap-4 lg:grid-cols-4">
        <NuxtLink
          v-for="(link, index) in sectionLinks"
          :key="index"
          class="group mb-4 flex flex-col justify-between justify-self-center"
          :to="link.path"
          :target="link.external ? '_blank' : ''"
        >
          <div
            class="safari-mask-transition mx-auto mb-4 size-24 transition-transform duration-150 group-hover:scale-110 xs:size-40 sm:size-64 lg:size-56"
          >
            <ShapedImage
              :index="index"
              :img-src="sectionSrc(link.imgSrc as keyof typeof sections)"
              class="size-full object-center"
            />
          </div>
          <div
            class="flex flex-1 items-center justify-center text-center text-md group-hover:underline"
          >
            <span class="mr-2">{{ link.title }}</span>
            <DynamicPictogram name="arrow" class="rotate-180" />
          </div>
        </NuxtLink>
      </div>
      <StartpageFeaturedProducts
        v-if="blackWeekProducts"
        class="mb-12"
        :products="blackWeekProducts?.products as any"
        :loading="
          Boolean(
            (featuredCategories?.length && !blackWeekProducts) ||
              blackWeekProducts?.loading
          )
        "
        :title="blackWeekProducts?.title"
        :tracking-data-list="`Startpage - ${blackWeekProducts?.title}`"
      />
      <StartpageFeaturedProducts
        v-else-if="featuredProducts"
        class="mb-12"
        :products="featuredProducts"
        :loading="featuredProductsPending"
        :title="$t('home.recommended_by_veterinarians')"
        :tracking-data-list="`Startpage - ${$t(
          'home.recommended_by_veterinarians'
        )}`"
      />
    </div>

    <div class="bg-background-extra-light">
      <div class="w-full bg-background-extra-light">
        <StartPageAboutSection />

        <div class="mx-auto max-w-6xl px-4 md:mt-6 md:px-6 md:pb-6">
          <LazyBannerList
            v-if="banners"
            :banners="banners"
            :show-primary="false"
            :is-startpage="true"
            :loading="bannersLoading"
            :loading-count-secondary="4"
            class="mb-12"
          />
        </div>
      </div>

      <template
        v-if="
          featuredCategories?.length && !featuredProductsForAnimalTypes?.length
        "
      >
        <div
          v-for="(_, i) in 2"
          :key="`featured-categories-skeleton-${i}`"
          class="px-4 md:px-6"
          :class="{
            'w-full pb-2 md:bg-background-extra-light': i === 0,
            'pt-8 md:bg-background-light': i !== 0,
          }"
        >
          <Grid class="mx-auto mb-16 h-[365px] max-w-6xl overflow-hidden">
            <ProductSkeleton v-for="n in 4" :key="n" />
          </Grid>
        </div>
      </template>
      <div
        v-for="(
          featuredProductsForAnimalType, i
        ) in featuredProductsForAnimalTypes.sort(
          (a, b) => a.sortIndex - b.sortIndex
        )"
        v-else
        :key="featuredProductsForAnimalType.path"
        class="px-4 md:px-6"
        :class="{
          'w-full pb-2 md:bg-background-extra-light': i === 0,
          'pt-8 md:bg-background-light': i !== 0,
        }"
      >
        <div class="mx-auto max-w-6xl">
          <StartpageFeaturedProducts
            :products="featuredProductsForAnimalType?.products"
            :loading="Boolean(featuredProductsForAnimalType?.loading)"
            :tracking-data-list="`Startpage - ${featuredProductsForAnimalType?.title}`"
          >
            <template #title>
              <NuxtLink
                v-if="featuredProductsForAnimalType.path"
                :to="
                  localePath(
                    `/${shopName}${featuredProductsForAnimalType.path}`
                  )
                "
                class="mb-4 flex flex-wrap items-baseline justify-between"
              >
                <h2 class="text-2xl md:ml-4">
                  {{ featuredProductsForAnimalType.title }}
                </h2>
                <span
                  class="flex items-center justify-center text-primary-default hover:underline md:mr-4"
                >
                  {{ $t('home.show_more') }}
                  <DynamicPictogram
                    name="arrow"
                    size="md"
                    class="ml-2 rotate-180"
                  />
                </span>
              </NuxtLink>
            </template>
          </StartpageFeaturedProducts>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { gql } from '@apollo/client/core';
import { mapProductsData } from '@/helpers/product-data-mapper';
import { productFragment } from '@/graphql/fragments';
import { useNavMenuStore } from '~/stores/navMenu';
import { useBannerStore } from '~/stores/banner';
import { startpageMobileNavButtonsClickEvent } from '~/utils/tracking-utils';
import HeroImgDesktopBlackWeek from '~/assets/images/black-week/hero_dog_desktop.webp?url';
import HeroImgMobileBlackWeek from '~/assets/images/black-week/hero_dog_mobile.webp?url';
import SectionPuffPharmacy from '~/assets/images/sections/puff-pharmacy.webp?url';
import SectionPuffDog from '~/assets/images/sections/puff-dog.webp?url';
import SectionPuffCat from '~/assets/images/sections/puff-cat.webp?url';
import SectionCampaigns from '~/assets/images/sections/230x203_campaigns.webp?url';
import SectionDogFood from '~/assets/images/sections/230x203_dog_food.webp?url';
import SectionDogSupplements from '~/assets/images/sections/230x203_dog_supplements.webp?url';
import SectionCat from '~/assets/images/sections/230x203_cat.webp?url';

const sections = {
  'sections/puff-pharmacy.webp': SectionPuffPharmacy,
  'sections/puff-dog.webp': SectionPuffDog,
  'sections/puff-cat.webp': SectionPuffCat,
  'sections/230x203_campaigns.webp': SectionCampaigns,
  'sections/230x203_dog_food.webp': SectionDogFood,
  'sections/230x203_dog_supplements.webp': SectionDogSupplements,
  'sections/230x203_cat.webp': SectionCat,
};

const sectionSrc = (section: keyof typeof sections) => sections[section];

const { blackWeekEnabledLocales, mobileStartpageNavButtonsEnabledLocales } =
  useRuntimeConfig().public.featureFlags;
const mobileNavButtons = ref({
  sv: [
    {
      title: 'Apotek',
      path: '/sv/djurapotek-hamta-ut-lakemedel-till-husdjur',
    },
    {
      title: 'Kampanjer',
      path: '/sv/butik/kampanjer',
    },
    {
      title: 'Hundmat',
      path: '/sv/butik/djurtyp/hund/hundfoder',
    },
    {
      title: 'Kattmat',
      path: '/sv/butik/djurtyp/katt/kattfoder',
    },
  ],
});
const localePath = useLocalePath();
const route = useRoute();

const navMenuStore = useNavMenuStore();
const bannerStore = useBannerStore();

const { locale } = useI18n();

const gtm = useGtm();

const shopName = computed(
  () => useShopCountryConfig().routes[useLanguage()].shop
);

definePageMeta({
  layout: 'full-width',
});

defineI18nRoute({
  locales: ['sv', 'en'],
  paths: {
    sv: '/butik',
    en: '/shop',
  },
});

if (process.server) {
  await navMenuStore.fetchCategoryTree();
}

watch(() => route.query, navMenuStore.fetchCategoryTree, {
  immediate: !process.server,
});

const featuredCategories = computed(() => navMenuStore.featuredCategories);
const animalTypes = computed(() => navMenuStore.animalTypes);
const sectionLinks = computed(() => useShopCountryConfig().homePageLinks);

const featuredProductsForAnimalTypes: FeaturedProducts[] = reactive([]);

const blackWeekProducts: Ref<Omit<FeaturedProducts, 'sortIndex'> | null> =
  ref(null);

let featuredProductCategoriesError: boolean = false;

function fetchAnimalTypeProducts(featCategories: any[]) {
  return Promise.all(
    featCategories.map(async (featuredCategory, index) => {
      const result = await useLazyAsyncQuery({
        query: gql`
          query Products(
            $categories: [Int!]
            $first: Int
            $after: String
            $sort: String
            $direction: String
          ) {
            products(
              categories: $categories
              first: $first
              after: $after
              sort: $sort
              direction: $direction
              brand: $brand
            ) {
              edges {
                node {
                  ...ProductFields
                  brand {
                    entityId
                    name
                    path
                    slug
                  }
                }
              }
            }
          }
          ${productFragment}
        `,
        variables: {
          categories: [featuredCategory.entityId],
          first: 12,
          after: '1',
          sort: 'total_sold',
          direction: 'desc',
          brand: 141,
        },
      });

      if (result.error.value) {
        featuredProductCategoriesError = true;
      }

      const title = featuredCategory.name.split('.').pop();

      const lowerCaseTitle = title.toLowerCase();

      const animalTypeCategory = animalTypes.value?.find((category) =>
        lowerCaseTitle.includes(category.name.toLowerCase())
      );

      if (animalTypeCategory) {
        featuredProductsForAnimalTypes.push({
          sortIndex: index,
          path: animalTypeCategory?.path,
          title,
          products: computed(() =>
            mapProductsData(result?.data?.value?.data?.products)
          ),
          loading: result.pending,
        });
      } else if (
        featuredCategory.path.includes('black-week') &&
        blackWeekEnabledLocales.includes(locale.value)
      ) {
        blackWeekProducts.value = {
          path: localePath({ name: 'campaigns-black-week' }),
          title,
          products: computed(() =>
            mapProductsData(result?.data?.value?.data?.products)
          ),
          loading: result.pending,
        };
      }
    })
  );
}

function trackPageView() {
  // FIXME: Disable impressions on startpage since payload to Google Analytics is too large.
  // Long term solution involves only sending impressions once the user actually sees the product.
  // const impressions = Object.values(this.productCarousels).reduce(
  //   (acc, ps) => [
  //     ...acc,
  //     ...ps.products.map(({ node }, index) => ({
  //       ...productTrackingData(node, index + 1),
  //       category: ps.title,
  //       list: `Startpage - ${ps.title}`,
  //     })),
  //   ],
  //   []
  // );

  gtm?.trackEvent(
    productListEvent(
      [] // impressions,
    )
  );
}

let bannerError = false;

if (process.server) {
  const { error: simpleBannersError } = await bannerStore.getSimpleBanners();
  if (simpleBannersError.value) {
    bannerError = true;
  }
} else if (!bannerStore.simpleBanners.length) {
  bannerStore.getSimpleBanners();
}

const bannersLoading = computed(() => bannerStore.simpleBannersLoading);
const banners = computed(() => bannerStore.getNonHiddenSimpleBanners);

// Fetch "starred"/featured products (called "recommended by our vets" on startpage)
const {
  data: featuredProductsResult,
  pending: featuredProductsPending,
  error: featuredProductsError,
} = await useLazyAsyncQuery(
  {
    query: gql`
      query PaginateFeaturedProducts {
        featuredProducts(first: 12) {
          pageInfo {
            startCursor
            endCursor
          }
          edges {
            cursor
            node {
              brand {
                entityId
                name
                path
                slug
              }
              categories {
                edges {
                  node {
                    entityId
                  }
                }
              }
              ...ProductFields
            }
          }
        }
      }
      ${productFragment}
    `,
  },
  { immediate: !blackWeekEnabledLocales.includes(locale.value) } // Don't fetch this when we should be showing Black friday featured products instead
);

const featuredProducts = computed(() => {
  if (featuredProductsResult?.value?.data?.featuredProducts) {
    return mapProductsData(
      featuredProductsResult?.value?.data?.featuredProducts
    );
  }
  return null;
});

onMounted(() => {
  trackPageView();
});

watch(
  featuredCategories,
  (featuredCategoriesValue) => {
    if (featuredCategoriesValue?.length) {
      fetchAnimalTypeProducts(featuredCategoriesValue);
    }
  },
  { immediate: true }
);
const trackMobileNavButtonClick = (title: string) => {
  gtm?.push(startpageMobileNavButtonsClickEvent(title));
};
</script>
